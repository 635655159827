import _, { isEmpty, set } from "lodash";
import { setProp } from "Store/Actions";
import { setError, showBoqLoader } from "Store/auth";
import { sCRMRegex } from "Components/Misc/CaseDetails_System";
import CONFIG_API from "Constants/services/configurator";
import {
  addCartItem
} from "Store/contentState/cartBoqSlice";
import { useConfigurator } from "./hooks/useConfigurator";
import { setCount_of_data } from "Store/contentState/configurationArea/dynamicSlice";
import { store } from "Store/mainStore";

let isDisabled = false;

const setDisabled = (val) => {
  isDisabled = val;
}

// common add to cart method to decide what logic to run depending on solution and step
export const addToCartLogic = (activePackages, packageData, selectors, undo_addCart?) => {
  const configstepperSelector = selectors.config_stepper;
  const activeStep = configstepperSelector.data.active_step;
  let connectivity_count_data = {};
  let datasetData = { ...selectors.dynamic.data.dataSet };
  const solutionCategory = configstepperSelector.data.solutionCategory;

  if (_.includes(["NDAC", "MPW"], solutionCategory) && !undo_addCart) {
    // if connectivity then set the hidden fields count of prod on family
    if (_.includes(['devices', 'connectivity'], activeStep)) {
      connectivity_count_data = setCount_of_parameters(activePackages, selectors);
    }
  }
  undo_addCart ? setUndo_AddCart(selectors, packageData, undo_addCart, datasetData, connectivity_count_data) : setAddCardData(selectors, packageData, datasetData, connectivity_count_data);
  return isDisabled;
}

export const setCount_of_parameters = (activePackages, selectors) => {
  let countArg = 'count_of_';
  const activestep = selectors.config_stepper.data.active_step;
  const cartData = selectors.dynamic.data.element[activestep];
  let items = [];
  if (cartData && cartData.connectivity_ndac_items) {
    items = cartData.connectivity_ndac_items;
  } else if (cartData && cartData.ndac_device_items) {
    items = cartData.ndac_device_items;
  }

  let catalogItems = [];
  if (cartData && cartData.catalog_items) {
    catalogItems = cartData.catalog_items;
  } else if (cartData && cartData.ndac_accessories) {
    catalogItems = cartData.ndac_accessories;
  }
  // let catalogItems = cartData.catalog_items ? cartData.catalog_items : cartData.ndac_accessories;
  if (_.keys(items).length > 0 && _.keys(catalogItems).length > 0) {
    items = { ...items, ...catalogItems }
  } else if (_.keys(items).length === 0 && _.keys(catalogItems).length > 0) {
    items = catalogItems;
  }

  const countData = {};
  let count_of_variables = {};
  for (let eachFamily in items) {
    if (typeof count_of_variables[activestep] !== 'object' || count_of_variables[activestep] === null) {
      count_of_variables[activestep] = {};
    }
    const countArgument = countArg + eachFamily.toLowerCase();
    let valueset = { 'key': 0, 'value': 0 };
    const data = _.filter(items[eachFamily], (each) => _.some(each, (item) => item === true));
    if (data.length > 0) {
      const countof_family = _.sumBy(data, obj => _.get(obj, 'quantity', 0));
      valueset = { 'key': countof_family, 'value': countof_family };
    }
    countData[countArg + eachFamily.toLowerCase()] = valueset;
    count_of_variables[activestep][countArgument] = valueset;
  }
  // total count of radio access points calculate and store in redux
  let sum_of_radioKits = 0;
  if (activestep === 'connectivity') {
    sum_of_radioKits = _.sum(_.map(countData, 'value'));
    selectors.dispatch(setProp({ slice: "dynamic", key: `element.${activestep}.number_of_access_points`, value: {'key' : sum_of_radioKits, 'value':sum_of_radioKits} }));
  }
  countData['number_of_access_points'] = sum_of_radioKits;
  // group all the count variables and dispatch at once
  selectors.dispatch(setCount_of_data(count_of_variables));
  return countData;
}



/** setAddCardData */
const setAddCardData = (selectors, packageData, datasetData, countData?) => {
  const dispatch = selectors.dispatch;
  const dynamicSelector = selectors.dynamic;
  const configstepperSelector = selectors.config_stepper;
  const solutionCategory = configstepperSelector.data.solutionCategory;
  let addCartValues = { ...dynamicSelector.data.element[configstepperSelector.data.active_step] };
  const packageDataAllFields = selectors.getAllFieldsInPackage();
  let setAddCardValues = {};
  // set hidden fields while adding to cart
  addHiddenFieldData(setAddCardValues, addCartValues, countData, selectors);
  setCartValues(setAddCardValues, addCartValues, packageDataAllFields, datasetData, selectors);
  let firstValue = { ...datasetData };
  let totalValueSet = setAddCardValues;
  let methodType = "POST";
  // PATCH call in case sid is set and not empty
  if (dynamicSelector.data && dynamicSelector.data.sid && (dynamicSelector.data.sid).length !== 0) {
    methodType = "PATCH";
    totalValueSet = { ...firstValue, ...setAddCardValues }
  }
  // check if MPW solution and has RRH config data set then append to the cart data to send to config
  const rrhdata = appendRRHConfigMPW(totalValueSet, selectors);
  if (rrhdata && !_.isEmpty(rrhdata)) {
    totalValueSet = { ...totalValueSet, ...rrhdata };
  }

  // check if MPW solution and has Radio Excel uploaded data set then append to the cart data to send
  const radioExcelData = appendRadioExcelData(totalValueSet, selectors);
  if (radioExcelData && !_.isEmpty(radioExcelData)) {
    totalValueSet = { ...totalValueSet, ...radioExcelData };
  }

  // Call addStepFormInputs for adding step wise user inputs
  addStepFormInputs(selectors, addCartValues, packageData);

  // customise offer add products data
  const dynamicData = { ...dynamicSelector.data.element[configstepperSelector.data.active_step] };
  if (dynamicData.customise_offer) {
    const customise_offer_addProducts = [];
    _.each(dynamicData.customise_offer['All'], (obj) => {
      if (obj.checked) {
        customise_offer_addProducts.push(obj.id);
      };
    });
    const addProdCart = { 'customise_offer': { 'addProducts': customise_offer_addProducts } };
    totalValueSet = { ...totalValueSet, ...addProdCart };
  }

  dispatch(setProp({ slice: "dynamic", key: 'dataSet', value: totalValueSet }));
  dispatch(setProp({ slice: "dynamic", key: 'actionNext', value: false }));
  addCartData(methodType, totalValueSet, selectors, packageDataAllFields);
}

/** addStepFormInputs
 * Method for addiing step wise user input details
 */
const addStepFormInputs = (selectors, addCartValues, packageData) => {
  const dispatch = selectors.dispatch;
  const configstepperSelector = selectors.config_stepper;
  const dynamicSelector = selectors.dynamic;
  const activeStep = configstepperSelector.data.active_step;
  // Get all the steps
  const stepperData = configstepperSelector.data.stepper_data;
  // Get current stepObject
  const currentStepObject = stepperData.find((e) => e.stepname === activeStep);
  // Take current StepFormInputs data from store
  let newStepFormInputs = _.cloneDeep(dynamicSelector.data.stepFormInputs);
  if (!newStepFormInputs[activeStep] || !(currentStepObject?.isChildActive === true)) {
    newStepFormInputs[activeStep] = {};
  }
  // Get all field keys for the active step
  Object.keys(addCartValues).forEach(fieldKey => {
    if (packageData?.[fieldKey]) {
      const fieldValue = prepareFormatedFieldValue(addCartValues?.[fieldKey]);
      newStepFormInputs[activeStep][fieldKey] = {
        type: packageData?.[fieldKey]?.type,
        id: packageData?.[fieldKey]?.id,
        label: packageData?.[fieldKey]?.label,
        value: fieldValue
      }
    }
  });
  dispatch(setProp({ slice: "dynamic", key: 'stepFormInputs', value: newStepFormInputs }));
}
/** prepareFormatedFieldValue */
const prepareFormatedFieldValue = (inputValue) => {
  if (Array.isArray(inputValue) && inputValue.every(obj => typeof obj === 'object' && 'key' in obj && 'value' in obj)) {
    return inputValue;
  }
  if(typeof inputValue === 'string' || 
    typeof inputValue === 'number' || 
    (Object.keys(inputValue).includes('key') && Object.keys(inputValue).includes('value'))){
    return inputValue;
  }
  let formattedInputValue = {};
  Object.keys(inputValue).forEach(iFr => {
    if (inputValue[iFr] && _.isArray(inputValue[iFr])) {
        formattedInputValue[iFr] = inputValue[iFr].filter(iFrEl => iFrEl?.checked === true);
    }
    // --- Some more logic may be needed here ??
  })
  return formattedInputValue;
}
/** removeStepFormInputs
 * Method for removing step wise user input details On undo action
 */
const removeStepFormInputs = (selectors, cartItems, packageData) => {
  const dispatch = selectors.dispatch;
  const configstepperSelector = selectors.config_stepper;
  const dynamicSelector = selectors.dynamic;
  // Get current active step
  const activeStep = selectors.config_stepper.data.active_step;
  // Get all the steps
  const stepperData = selectors.config_stepper.data.stepper_data;

  // Get current stepObject
  const currentStepObject = stepperData.find((e) => e.stepname === activeStep);
  // Get all further step list
  const furtherStepList = [];
  stepperData.forEach(step => {
    if (parseInt(step.stepNumber) > parseInt(currentStepObject.stepNumber)) {
      furtherStepList.push(step.stepname)
    }
  });

  // Take the curent stepFormInputs store copy
  let newStepFormInputs = _.cloneDeep(dynamicSelector.data.stepFormInputs);

  // Check whether current active step is having sub steps
  if (currentStepObject?.isChildActive === true) {
    // Get active sub step
    const activeSubstep = selectors.config_stepper.data.active_substep;
    const subSteps = currentStepObject.subSteps;

    // Get current active sub step object
    const currentSubStepObject = subSteps.find((e) => e.stepname === activeSubstep);
    let furtherSubStepFieldList = [];

    subSteps.forEach(subStep => {
      // Take sub step till current active sub step - to keep in the store
      if (parseInt(subStep.stepNumber) >= parseInt(currentSubStepObject.stepNumber)) {
        const __fields = getSubStepFieldList(activeStep, subStep.stepname, configstepperSelector);
        furtherSubStepFieldList = [...furtherSubStepFieldList, ...Object.keys(__fields)];
      }
    });

    // Loop through the current step store and prepare new store by excluding the furtherSubStepFieldList
    let updatedStepStore = {};
    Object.keys(newStepFormInputs[activeStep]).forEach(storeField => {
      if(!furtherSubStepFieldList.includes(storeField)){
        updatedStepStore[storeField] = newStepFormInputs[activeStep][storeField];
      }
    });
    // Update store with updated state
    newStepFormInputs[activeStep] = updatedStepStore;
  }
  else {
    // Take all the further steps + current step to the list for remove state
    furtherStepList.unshift(currentStepObject.stepname)
  }
  // Exclude all the step in the list - furtherStepList
  const updatedStepFormInputs = {}
  Object.keys(newStepFormInputs).forEach(stepName => {
    if (!furtherStepList.includes(stepName)) {
      updatedStepFormInputs[stepName] = newStepFormInputs[stepName];
    }
  });
  // Dispatch updated store data 
  dispatch(setProp({ slice: "dynamic", key: 'stepFormInputs', value: updatedStepFormInputs }));
}

/** getSubStepFieldList */
const getSubStepFieldList = (stepName, subStepName, configstepperSelector) => {
  const packageData = configstepperSelector.data.solution_form_data["packages"];
  let field_details = {};
  if (
    packageData &&
    packageData[stepName] &&
    packageData[stepName].field_details
  ) {
    let fieldList = packageData[stepName].field_details;
    if (fieldList[subStepName] && fieldList[subStepName].field_details) {
      // Handle the sub step field lis
      fieldList = fieldList[subStepName].field_details
    }
    Object.keys(fieldList).forEach((field) => {
      if (fieldList[field].type === "container") {
        field_details = { ...field_details, ...fieldList[field].field_details };
      }
      else {
        field_details = { ...field_details, ...fieldList[field] };
      }
    });
  }
  return field_details;
}

/** setCartValues */
const setCartValues = (setAddCardValues, addCartValues, packageData, datasetData, selector) => {
  let considerValArrMxie = [];
  Object.entries(addCartValues).map((data, _index) => {
    // add this if required later - for cmu
    const stateOfElement = packageData[data[0]] ? selector.getElementsStates(packageData[data[0]]) : undefined;
    // const stateOfElement = selector.dynamic.stateInfo[selector.config_stepper.data.active_step][packageData[data[0]]];
    if (stateOfElement && stateOfElement['visibleValue'] !== 'none') {
      if (data[1] !== '') {
        let productlist = [];

        if (data[0] && packageData[data[0]] && packageData[data[0]]['data_callback'] && packageData[data[0]]['data_callback']['button']) {
          productlist = prepareProductList(data);
          setAddCardValues[data[0]] = productlist.flat()
        }
        else {
          if (Array.isArray(data[1])) {
            setAddCardValues[data[0]] = data[1].map((item) => item.key)
          }
          else if (typeof data[1] === 'object') {
            if (packageData[data[0]] && packageData[data[0]]['data_callback'] && packageData[data[0]]['data_callback']['target_field'] === 'mxie_server') {
              productlist = prepareProductList(data, 'object');
              if (productlist && productlist.length > 0) {
                const selectedMXIE = productlist[0].product_code;
                setAddCardValues[data[0]] = productlist[0].id;
                // add mxie step attributes for selected mxie server
                if (_.includes(_.keys(selector.dynamic.data.element[selector.config_stepper.data.active_step]), 'selected_mxie_server')) {
                  selector.dispatch(setProp({ slice: "dynamic", key: `element.${selector.config_stepper.data.active_step}.selected_mxie_server`, value: selectedMXIE }));
                  datasetData['selected_mxie_server'] = selectedMXIE;
                }
              }
            }
            else {
              if (selector.config_stepper.data.active_step === 'mxie') {
                considerValArrMxie = ['vcpu', 'storage', 'memory', 'pod'];// TODO: Fix this hardcoded
              }
              // if the step  mxie and type is hidden then send values and not keys
              setAddCardValues[data[0]] = _.includes(considerValArrMxie, data[1]['key']) ? data[1]['value'] : data[1]['key'];
            }
          }
          else {
            setAddCardValues[data[0]] = data[1]
          }
        }
      }
    } else {
      if (_.includes(data[0], 'count_of')) {
        setAddCardValues[data[0]] = data[1]['key'];//data[1].map((item) => item.key)
      }
    }
  });

  Object.keys(packageData).forEach(function (itm) {
    const stateOfElement = selector.getElementsStates(packageData[itm]);
    // const stateOfElement = selector.dynamic.stateInfo[selector.config_stepper.data.active_step][packageData[itm]];
    if (stateOfElement['visibleValue'] === 'none') {
      if (datasetData[itm] && packageData[itm].type !== 'hidden') {
        datasetData = _.omit(datasetData, itm);
        selector.dispatch(setProp({ slice: "dynamic", key: 'dataSet', value: datasetData }));
      }
    }
  });
}

const addHiddenFieldData = (setAddCardValues, addCartValues, countData, selectors) => {
  // send curreny and country in payload in all solutions
  setAddCardValues['country'] = selectors.caseDetails.data.country;
  setAddCardValues['currency'] = selectors.caseDetails.data.currency;
  const solutionCategory = selectors.config_stepper.data.solutionCategory;

  if (_.includes(['NDAC', 'MPW'], solutionCategory)) {
    const commercialModel = _.toUpper(selectors.dynamic.data.reference_solution.commercial_model);
    setAddCardValues['commercial_model'] = selectors.dynamic.data.reference_solution.commercial_model;
    setAddCardValues['contract_duration_months'] = commercialModel === "OPEX" ? selectors.dynamic.data.reference_solution.contract_duration : "";
    setAddCardValues['contract_duration_years'] = commercialModel === "CAPEX" ? selectors.dynamic.data.reference_solution.contract_duration : "";
    setAddCardValues['case_type'] = selectors.findCase.data.caseType;
    setAddCardValues['case_sub_type'] = selectors.findCase.data.subType;
    _.map(countData, (counter, key) => {
      setAddCardValues[key] = counter;
      addCartValues[key] = counter;
    });
  }
}

const addCartData = async (method, cartDataList, selectors, package_data, undo = false) => {
  const dispatch = selectors.dispatch;
  const dynamicSelector = selectors.dynamic;
  const configstepperSelector = selectors.config_stepper;
  const caseDetailsSelector = selectors.caseDetails;
  const findCaseSelector = selectors.findCase;
  const solutionCategory = configstepperSelector.data.solutionCategory;

  setDisabled(true);
  let cartData = cartDataList;
  let solutionName: string = configstepperSelector.data.solution_selected
  const sid = dynamicSelector.data.sid !== "" ? dynamicSelector.data.sid : "";

  // parameters that are required for eot waterfall api
  const market = caseDetailsSelector.data.market;
  const currency = caseDetailsSelector.data.currency;
  const networkcountry = caseDetailsSelector.data.country; // network country
  const scrmid = !sCRMRegex.test(findCaseSelector.data.sCRMID) ? "" : findCaseSelector.data.sCRMID;
  const threestar = caseDetailsSelector.data.three_star_number;
  const account_name = caseDetailsSelector.data.account_name;
  const account_id = caseDetailsSelector.data.account_id;
  const sales_channel = caseDetailsSelector.data.sales_channel;
  let function_operated = setFlagForEOTAPI(solutionName, solutionCategory, configstepperSelector, undo);
  // add country and currency as data for service api call
  if (_.includes(['MPW'], solutionCategory)) {
    cartData = { ...cartDataList, 'country': networkcountry, 'currency': currency };
  }
  const cartDataSend = { "status": "draft", "segment": solutionName, "data": cartData };

  // parameters required by EOT api , send along with config api call
  let eotParams = "market=" + market + "&currency=" + currency + "&country_code=" + networkcountry + "&scrm_id=" + scrmid + "&threestar=" + threestar + "&account_name=" + account_name + "&operation=" + function_operated + "&account_id=" + account_id + "&sales_channel=" + sales_channel;

  if (_.includes(['NDAC'], solutionCategory) && (_.includes(['services', 'sla_warranty'], configstepperSelector.data.active_step))) {
    eotParams = eotParams + "&solutiontype=ndac_services";
  }

  if (solutionCategory === 'MPW' && configstepperSelector.data.solution_selected !== 'cmu') {
    eotParams = eotParams + "&solutiontype=mpw";
  } else if (configstepperSelector.data.solution_selected === 'cmu') {
    eotParams = eotParams + "&solutiontype=cmu";
  }

  // set loader in boq for configurator
  dispatch(showBoqLoader(true));
  const packageData = package_data;
  await CONFIG_API.segmentFormSubmission(method, solutionName, cartDataSend, sid, eotParams, undo)
    .then((resp) => {
      if (resp.status) {
        // if data is present then process the response
        if(!_.isEmpty(resp.data)) {
          process_response(resp, dispatch, selectors, packageData, solutionCategory,cartDataList);
        } 
        let disableValue = undefined; 
        // if the validate json is not empty then show the messages
        if(resp.validateResponse && resp.validateResponse.length > 0) {
          // show the messages
          const disableFlag = selectors.showValidateMessages(resp.validateResponse);
          disableValue = {disableNext : disableFlag , validateResponse : resp.validateResponse};
        }
        dispatch(
          setProp({
            slice: "dynamic",
            key: `validationDetails`,
            value: disableValue,
          })
        );
      } else {
        dispatch(
          setError({
            key: "error",
            value: {
              errorCode: resp.errorCode,
              errorMsg: resp.errorMessage,
            },
          })
        );
        setDisabled(false);
      }
      dispatch(showBoqLoader(false));
    })
    .catch((e) => {
      dispatch(
        setError({
          key: "error",
          value: {
            errorCode: e,
            errorMsg: "Timeout",
          },
        })
      );
      setDisabled(false);
      dispatch(showBoqLoader(false));
    });
  dispatch(setError("reset"));
}

const process_response = (resp, dispatch, selectors, packageData, solutionCategory,cartDataList) => {
  const configstepperSelector = selectors.config_stepper;
  const dynamicSelector = selectors.dynamic;

  let productList = [];
  let mpwNDACProductList = [];
  const respConfig = resp.data?.final_response ? resp.data?.final_response : resp.data;
  let responseData = respConfig.boqData?.data?.saleItems ? respConfig.boqData?.data?.saleItems : [];
  if (_.includes(['NDAC', 'MPW'], solutionCategory)) {
    // get eot response and store in redux to send to syncoffer
    if (resp.data.eot_response && (resp.data.eot_response).length > 0) {
      dispatch(setProp({ slice: "dynamic", key: 'eot_response_syncoffer', value: resp.data.eot_response }));
    }
    if (_.includes(['services', 'sla_warranty'], configstepperSelector.data.active_step)) {
      // merge configurator services and ndac services
      responseData = responseData.map(obj => {
        if (obj.type === 'end_end_services') {
          return { ...obj, "oot_displaySection": "deployment_technical" }
        } else {
          return obj;
        }
      });
      const end_end_servicesData = get_ServiceDataGroup(responseData.filter((e) => e.type === "end_end_services"));
      responseData = responseData.filter((e) => e.type !== "end_end_services");
      // combine services which is coming from oot and configurator
      // let servicesData = [...dynamicSelector.data.selected_ndac_services, ...end_end_servicesData];
      responseData = [...responseData, ...end_end_servicesData];
      // dispatch(setProp({ slice: "dynamic", key: `finalCombined_ndac_services`, value: servicesData }));
    }
  }
  responseData.map((item, index) => {
    if (_.includes(['NDAC', 'MPW'], solutionCategory) && (_.includes([null, 'MXIE', 'NDAC'], item.financialProduct) || _.includes([null, 'MXIE', 'NDAC'], item.financial_product))) {
      // products , subscription license
      // Non EOT responses should take price from OOT products ( NDAC items )
      const response_product_code = item.productCode ? item.productCode : item.product_id;
      let productdata = dynamicSelector.data.allProducts.filter((itm) => itm.product_id === response_product_code)
      if (productdata[0]) {
        let productdataListData = {}
        Object.entries(productdata[0]).forEach(itemn => {
          productdataListData[itemn[0]] = itemn[1]
        })
        // this is if type is already there then assign the type instead of taking from product
        if (solutionCategory === 'MPW' && item.type) {
          // for MPW solution for product_attached_services we need product price
          // as for NDAC it will take price and type from product itself we want to override it
          // this is needed to show MPW solution , NDAC service in product attached service
          productdataListData['type'] = item['type'];
          productdataListData['work_package'] = item['workPackage'];
          productdataListData['work_item'] = item['workItem'];
        }
        let productlistdata = [];

        const existingProducts = dynamicSelector.data.selectedProductsData && (dynamicSelector.data.selectedProductsData).length > 0 ? dynamicSelector.data.selectedProductsData : [];

        if (configstepperSelector.data.active_step === 'devices' || configstepperSelector.data.active_step === 'connectivity' || configstepperSelector.data.active_step === 'sla_warranty') {
          Object.entries(dynamicSelector.data.element[configstepperSelector.data.active_step]).map((data, _index) => {
            if (packageData[data[0]] && packageData[data[0]]['data_callback'] && packageData[data[0]]['data_callback']['button']) {
              Object.entries(data[1]).forEach(item => {
                if (item[1] && (_.isArray(item[1])) && item[1].length > 0) {
                  const checkedItem = item[1].filter((itm) => itm.checked);
                  productlistdata.push(checkedItem);
                }
              });
              const selectedProducts = _.map(_.flatten(productlistdata), ele => _.pick(ele, ['product_code', 'quantity']));

              const finalProductList = _.unionBy(selectedProducts, existingProducts, 'product_code');
              dispatch(setProp({ slice: "dynamic", key: 'selectedProductsData', value: finalProductList }));
            }
          })
        }
        if (existingProducts.length > 0) {
          const foundItem = _.find(existingProducts, (prod) => prod.product_code === response_product_code);
          if (foundItem) {
            item['quantity'] = foundItem['quantity'];
          }
        }
        let productdatalist = productlistdata.flat().filter((itm) => itm.product_code === productdata[0].product_id)
        if (productdatalist[0]) {
          productdataListData['quantity'] = productdatalist[0].quantity
        }
        else {
          productdataListData['quantity'] = item.quantity
        }
        if (item.oot_displaySection) {
          productdataListData['oot_displaySection'] = item.oot_displaySection;
        }
        if (configstepperSelector.data.active_step === 'sla_warranty' && item.deviceProductID === 'SLA') {
          productdataListData['category'] = 'sla'
        }
        productdataListData['cost'] = parseFloat(productdata[0].list_price) * parseInt(productdataListData['quantity']);
        productList.push(productdataListData);
      }
      else {
        productList.push(item);
      }
      mpwNDACProductList.push(item);
    } else {
      // EOT responded already have price ( CMU , MPW connectivity , MPW service items )
      const productDetails = { product_name: "", list_price: 0, cost: 0, product_id: "", quantity: 0, type: "", work_package: "", duration: null, work_item: "" };

      // add duration logic here to show duration on UI and in BOQ store both
      productDetails.duration = processDuration(item, cartDataList);
      item['duration'] = Math.max(1, productDetails.duration);

      let finalCost = item.total_pnp_with_incoterm !== undefined ? item.total_pnp_with_incoterm : item.cost;
      /* For only care and srs products we need pnp_with_incoterm and not total_pnp_with_incoterm
      as at EOT end its multiplied with duration */
      if(item.type === 'care' || item.type === 'srs') {
        finalCost = item.pnp_with_incoterm !== undefined ? item.pnp_with_incoterm : 0;
      }

      const finalPrice = parseFloat(finalCost) * item['duration'];

      productDetails.product_name = item.work_item;
      productDetails.list_price = finalPrice;
      productDetails.cost = finalPrice;
      productDetails.product_id = item.si_code;
      productDetails.quantity = Math.max(1, item.quantity);
      productDetails.type = item.type;
      productDetails.work_package = item.work_package;
      productDetails.work_item = item.work_item;

      item['quantity'] = productDetails.quantity;
      // add total_pnp_with_incoterm = pnp_with_incoterm * duration
      item['total_pnp_with_incoterm'] = finalPrice;
      if (item.oot_displaySection) {
        productDetails['oot_displaySection'] = item.oot_displaySection;
      }
      productList.push(productDetails);
    }
  });
  dispatch(setProp({ slice: "dynamic", key: `sid`, value: respConfig.sid }));
  dispatch(
    addCartItem({
      products: productList,
      history: true,
      configurator: true
    })
  );
  setDisabled(false);
  // add to boq for ndac reference solutions only / cmu and mpw_reference solution add to boq
  addBoqDataToStore(dispatch,solutionCategory,productList,respConfig,mpwNDACProductList);
}

const addBoqDataToStore = (dispatch,solutionCategory,productList,respConfig,mpwNDACProductList) => {
  // add to boq for ndac reference solutions only
  if (_.includes(['NDAC'], solutionCategory)) {
    dispatch(setProp({ slice: "dynamic", key: `boq`, value: productList }));
  }
  else {
    // cmu and mpw_reference solution add to boq
    let boq_items = respConfig?.boqData?.data?.saleItems;
    if (mpwNDACProductList.length > 0) {
      // made this from product code to product title as product codes can be duplicate
      const mpw_items = boq_items.filter(obj1 => !mpwNDACProductList.some(obj2 => obj1['productTitle'] === obj2['productTitle']));
      boq_items = [...mpw_items, ...mpwNDACProductList];
    }
    dispatch(setProp({ slice: "dynamic", key: `boq`, value: boq_items }));
  }
}

// decide if eot api has to be called or not
const setFlagForEOTAPI = (solutionName, solutionCategory, configstepperSelector, undo) => {
  let function_operated = "add_to_cart";
  // if (solutionName === 'cdm_ug_ndac') {
  //   function_operated = "";
  // }
  // we dont need eot api call for other add to cart
  if (_.includes(['NDAC'], solutionCategory) && (!_.includes(['services', 'sla_warranty'], configstepperSelector.data.active_step))) {
    function_operated = "";
  }

  // if(solutionName === 'mpw_reference_solution' && !_.includes(['connectivity','mxie','services_mpw','care_srs_sla_warranty'],configstepperSelector.data.active_step)) {
  //   function_operated = "";
  // }
  // when step is in services and we do undo no need to call EOT waterfall api
  if ((_.includes(['NDAC'], solutionCategory) && (configstepperSelector.data.active_step === 'services')) && undo) {
    function_operated = "";
  }

  // when step is in connectivity and substep is cmu and we do undo no need to call EOT waterfall api
  if ((_.includes(['MPW'], solutionCategory) && _.includes(['connectivity'], configstepperSelector.data.active_step) && configstepperSelector.data.active_substep === 'cmu_connectivity') && undo) {
    function_operated = "";
  }
  return function_operated;
}

// function for services to send as group in ndac ref solution
const get_ServiceDataGroup = (servicesDataList) => {
  let end_end_services_list = [];
  servicesDataList.forEach(element => {
    // if ':' is present and whatever is previous to ':' are same it will be grouped as one service
    let aa = element.work_package.split(":");
    let end_end_services_details = {}
    end_end_services_details['total_pnp_with_incoterm'] = element.total_pnp_with_incoterm;
    end_end_services_details['work_package'] = _.trim(aa[0]);
    end_end_services_list.push(end_end_services_details)
  });
  let end_end_groups = {}
  end_end_services_list.forEach((employee) => {
    // let { work_package } = employee;
    if (!end_end_groups[employee.work_package]) {
      end_end_groups[employee.work_package] = [];
    }
    end_end_groups[employee.work_package].push(employee);
  });
  let end_end_servicesData_List = []
  Object.entries(end_end_groups).map((data, _index) => {
    let data_list = {};
    data_list['work_package'] = data[0];
    data_list['cost'] = end_end_groups[data[0]].map(o => o.total_pnp_with_incoterm).reduce((a, c) => { return a + c });
    data_list['type'] = 'end_end_services';
    data_list['oot_displaySection'] = 'deployment_technical';
    end_end_servicesData_List.push(data_list);
  })
  return end_end_servicesData_List
}

// Logic to set the duration for sale items on BOQ
const processDuration = (saleItem, cartData) => {
  let duration = null;
  const licensetype = cartData['license_type'];//dynamicSelector.data.dataSet['license_type'];
  const tco_years = cartData['tco_years'];
  if (saleItem.type === 'software') {
    // duration will be 1 for perpectual-software always
    duration = 1;
    if (licensetype && licensetype === 'Term based') {
      // duration will be 3 for term based - software initially
      duration = 3;
      if (tco_years && tco_years !== "") {
        // duration will be tco years - software , when tco is selected
        duration = tco_years;
      }
    }
  } else if (saleItem.type === 'care' || saleItem.type === 'srs') {
    // if tco is selected the set duration
    if (tco_years && tco_years !== "") {
      duration = tco_years;
    }
  }
  return duration;
}

const setUndo_AddCart = (selectors, packageData, undo_addCart, dataset, countData?) => {
  let addCartValues = undo_addCart;
  addCartValues = { ...addCartValues };
  let setAddCardValues = {};
  // set hidden fields while adding to cart
  addHiddenFieldData(setAddCardValues, addCartValues, countData, selectors);
  const packageDataAllFields = selectors.getAllFieldsInPackage();
  setCartValues(setAddCardValues, addCartValues, packageDataAllFields, dataset, selectors);
  let totalValueSet = { ...setAddCardValues };
  let methodType = "PATCH";
  let finalOptions = setNextStepsNull(totalValueSet, dataset);
  selectors.dispatch(setProp({ slice: "dynamic", key: 'dataSet', value: finalOptions }));
  // remove the default value set from callbacks
  undoCallBackDefault(selectors);

  // check if MPW solution and has RRH config data set then append to the cart data to send to config
  const rrhdata = appendRRHConfigMPW(finalOptions, selectors);
  if (rrhdata && !_.isEmpty(rrhdata)) {
    finalOptions = { ...finalOptions, ...rrhdata };
  }

  // check if MPW solution and has Radio Excel uploaded data set then append to the cart data to send
  let radioExcelData = [];
  if (selectors.config_stepper.data.active_substep !== 'radio_solution_connectivity') {
    radioExcelData = appendRadioExcelData(finalOptions, selectors);
  }
  if (radioExcelData && !_.isEmpty(radioExcelData)) {
    finalOptions = { ...finalOptions, ...radioExcelData };
  }

  // Remove items from stepFormInputs store
  removeStepFormInputs(selectors, finalOptions, packageData);

  addCartData(methodType, finalOptions, selectors, packageData, true);
}

const undoCallBackDefault = (selectors) => {
  const currentStep = selectors.config_stepper.data.active_step;
  const allOtherSteps = _.pull(_.keys(selectors.dynamic.data.callbackdefaults), currentStep)
  _.forEach(allOtherSteps, (eachStepName) => {
    selectors.dispatch(setProp({ slice: "dynamic", key: `callbackdefaults.${eachStepName}`, value: null }));
  });
}

const setNextStepsNull = (currentOptions, dataset) => {
  _.forEach(dataset, (value, key) => {
    if (!_.includes(['commercial_model', 'contract_duration', 'country', 'currency', 'case_type', 'case_sub_type'], key)) {
      if (!currentOptions.hasOwnProperty(key)) {
        dataset[key] = null;
      }
    }
  });
  return dataset;
};

const findModalObject = (obj, findKey) => {
  if (_.isArray(obj)) {
    return null;
  }
  if (obj && typeof obj === 'object') {
    if (obj[findKey]) {
      return obj[findKey];
    }
    for (let key in obj) {
      const result = findModalObject(obj[key], findKey);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

const appendRRHConfigMPW = (cartData, selectors) => {
  const dynamicSelector = selectors.dynamic;
  const configstepperSelector = selectors.config_stepper;
  if (dynamicSelector.data.modal && !_.isEmpty(dynamicSelector.data.modal)) {
    const rrhObj = findModalObject(dynamicSelector.data.modal, 'RRH');
    let config_data = {};
    if (rrhObj && !_.isEmpty(rrhObj)) {
      const rrhconfig = _.values(rrhObj);
      // const rrhdata =  _.map(rrhconfig, obj => _.mapValues(obj,each => each.key ));
      const rrhdata = _.map(rrhconfig, obj => {
        return _.mapValues(obj, each => {
          if (_.isArray(each)) {
            return _.map(each, item => item.key);
          }
          return each.key;
        });
      });
      config_data = { 'rrh_configuration': rrhdata ? rrhdata : [] };
    }

    cartData = { ...cartData, ...config_data };
  }
  return cartData;
}
/** appendRadioExcelData */
const appendRadioExcelData = (cartData, selectors) => {
  const dynamicSelector = selectors.dynamic;
  const radioExcelJson = findModalObject(dynamicSelector.data.modal, 'radioExcelJson');
  if (radioExcelJson && !_.isEmpty(radioExcelJson)) {
    let config_data = { 'radioExcelJson': radioExcelJson };
    cartData = { ...cartData, ...config_data };
  }
  return cartData;
}

// common method for product list preparation to loop the items and push to given variable if item is array
const prepareProductList = (data, type = 'array') => {
  let productlist = [];
  if (type === 'array') {
    Object.entries(data[1]).forEach(item => {
      if (item[1] && (_.isArray(item[1])) && item[1].length > 0) {
        const checkitems = item[1].filter((itm) => itm.checked);
        if (checkitems) {
          productlist.push(item[1].filter((itm) => itm.checked).map((ite) => ite.id))
        }
      }
    });
  } else {
    Object.entries(data[1]).forEach(item => {
      if (item[1] && _.isArray(item[1])) {
        productlist = (item[1].filter((itm) => itm.checked)
          .map((ite) => ({ id: ite.id, product_code: ite.product_code })));
      }
    });
  }
  return productlist;
}