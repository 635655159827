import React, { useEffect, useState, Fragment } from "react";
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import { TextInputLabelContent } from "@nokia-csf-uxr/ccfk/TextInput";
import { Label, Checkbox, ToggleSwitch } from "@nokia-csf-uxr/ccfk";
import { ToggleSwitchLabelContent } from '@nokia-csf-uxr/ccfk/ToggleSwitch'
import ToggleIcon from '@nokia-csf-uxr/ccfk-assets/latest/ToggleIcon'
import ToggleDisabledIcon from '@nokia-csf-uxr/ccfk-assets/latest/ToggleDisabledIcon';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import { HelpText } from "../hooks/useHelpText";
interface DataList {
    data: Array<any>,
    optionsData: object,
    active_step: string,
    updateDispatchStore?: Function,
    isDisabled: boolean,
    errorMessageText: Function,
    displayLayout: string
    dataStore?: Object
}

const CheckboxField = (props: DataList) => {
    // const dynamicSelector = useSelector((state: RootState) => state.dynamic);
    const label = props.data[1].required ? props.data[1].label + ' *' : props.data[1].label
    const dispatch = useDispatch();
    const emptyOptions: any = []
    const [errorDisabled, setErrorDisabled] = useState(props.data[1].required);
    const disableState = props.data[1].disabled ? props.data[1].disabled : props.isDisabled;
    const {
        showHelpText
    } = HelpText();

    useEffect(() => {
        // --- This block of code can be seperated and can be global accesible
        if (props.data[1].required) {
            // if (dynamicSelector.data.element[props.active_step]) {
            if (props.dataStore) {
                if (props.dataStore[props.data[0]] !== "") {
                    setErrorDisabled(false)
                }
                else {
                    setErrorDisabled(true)
                }
            }
        }
    }, [props.dataStore])

    const getKeyvalueData = (selectkey) => {
        // let optionsList = props.optionsData[props.active_step][props.data[0]]
        let optionsList = props.optionsData[props.data[0]]
        let selectKeyValue = {}
        optionsList.forEach(element => {
            if (element.id === selectkey) {
                selectKeyValue['key'] = element.id
                selectKeyValue['value'] = element.value
            }
        });
        return selectKeyValue
    }

    const getCheckedItem = (checkbox) => {
        const val = { key: checkbox.id, value: checkbox.value };
        // return dynamicSelector.data.element[props.active_step][props.data[0]].some((item) => shallowEqualityCheck(item, val))
        return props.dataStore && props.dataStore[props.data[0]] && props.dataStore[props.data[0]].some((item) => shallowEqualityCheck(item, val))
    }

    const shallowEqualityCheck = (obj1, obj2) => {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (const key of keys1) {
            if (obj1[key] !== obj2[key]) {
                return false;
            }
        }
        return true;
    }

    const setArrayElement = (method, activeStep, useCase, values) => {
        // let element = dynamicSelector.data.element;
        let element = props.dataStore;
        let selectedValues = [];
        if (method === "add") {
            // selectedValues = [...element[activeStep][useCase], values];
            selectedValues = [...element[useCase], values];
        }
        if (method === "remove") {
            // selectedValues = element[activeStep][useCase].filter((e) => e.key !== values.key);
            selectedValues = element[useCase].filter((e) => e.key !== values.key);
        }
        props.updateDispatchStore(useCase, selectedValues);
    }

    const handleChange = (e) => {
        let selectKeyValue = { 'key': 0, 'value': 0 }
        if (e.value) {
            selectKeyValue['key'] = 1
            selectKeyValue['value'] = 1
        }
        getToggleValue(true)
        props.updateDispatchStore(props.data[0], selectKeyValue);
    };

    const getToggleValue = (toggle) => {
        let togglestatus = toggle;
        // if (dynamicSelector.data.element[props.active_step]) {
        if (props.dataStore) {
            if (props.dataStore[props.data[0]]) {
                if (parseInt(props.dataStore[props.data[0]].value) === 1) {
                    togglestatus = true
                }
                else {
                    togglestatus = false
                }
            }
        }
        return togglestatus
    }

    return (
        <>
            {props.data[1]['subtype'] && props.data[1]['subtype'] === 'toggle' ? (
                <div style={{ display: 'inline-flex' }}>
                    <Label>
                        {/* <Tooltip
                            placement="top"
                            trigger="hover"
                            tooltip={getToggleValue(false) ? 'Power On' : 'Power Off'}
                            closeOnReferenceHidden={false}
                            modifiers={
                                [{ name: 'offset', options: { offset: [0, 10] } }]
                            }
                        > */}
                            <ToggleSwitch
                                variant="plain"
                                disabled={disableState}
                                checked={getToggleValue(false)}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': '', type: 'button', role: 'switch', 'aria-checked': getToggleValue(false) }}
                                toggleIcon={getToggleValue(false) ? <ToggleIcon /> : <ToggleDisabledIcon />}
                            />
                        {/* </Tooltip> */}
                        <TextInputLabelContent style={{ marginLeft: "10px" }}>{label}</TextInputLabelContent>
                    </Label>
                    {showHelpText(props.data)}
                </div>
            ) : (<>
                {props.optionsData && props.optionsData[props.data[0]] &&
                    (props.optionsData[props.data[0]]).length !== 0 ?
                    <div style={{ display: 'flex' }}>
                        <TextInputLabelContent>
                            {label}
                        </TextInputLabelContent>
                        {showHelpText(props.data)}
                    </div>
                    : <></>
                }
                {Object.entries(props.optionsData).length === 0 ? emptyOptions :
                    props.optionsData[props.data[0]]?.map((checkbox, i) => {
                        return (
                            <>
                                <div className='ml'
                                    style={{
                                        display: props.displayLayout,
                                        // width: "50%",
                                        marginLeft: "0px"
                                    }}>
                                    <Checkbox
                                        checked={getCheckedItem(checkbox)}
                                        disabled={disableState}
                                        onChange={(e) => {
                                            const checkKeyValue = getKeyvalueData(checkbox.id);
                                            if (e.target.checked) {
                                                setArrayElement("add", props.active_step, props.data[0], checkKeyValue);
                                                // dispatch(setArrayElement({ method: "add", activeStep:props.active_step, useCase: props.data[0] , device: checkKeyValue }));
                                                // props.updateDispatchStore(props.data[0],dynamicSelector.data.element[props.active_step][props.data[0]],true);
                                                return;
                                            }
                                            setArrayElement("remove", props.active_step, props.data[0], checkKeyValue);
                                            // dispatch(setArrayElement({ method: "remove", activeStep:props.active_step, useCase: props.data[0], device: checkKeyValue }));
                                            // props.updateDispatchStore(props.data[0],dynamicSelector.data.element[props.active_step][props.data[0]],true);
                                        }} />
                                    <TextInputLabelContent style={{ marginLeft: "0px" }}>{checkbox.value}</TextInputLabelContent>

                                </div>
                                {(errorDisabled ? <>{props.errorMessageText(props.data[1].label)}</> : '')}
                            </>
                        )
                    })
                }
            </>)}
        </>
    )
}

export default CheckboxField;