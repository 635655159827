import { AppBanner, Avatar, ButtonsRow, Skeleton, Typography } from "@nokia-csf-uxr/ccfk";
import { AppHeader } from "@nokia-csf-uxr/ccfk/App";
import {
  AppBannerContent,
  AppBannerDrawer,
  AppBannerLogo,
  AppBannerName,
  AppBannerNameSecondary,
} from "@nokia-csf-uxr/ccfk/AppBanner";
import AppMenu from "@nokia-csf-uxr/ccfk/AppMenu";
import UserAccountSummary, {
  UserAccountSummaryButton,
  UserAccountSummaryDescription,
  UserAccountSummaryFooter,
  UserAccountSummaryHeader,
  UserAccountSummaryPopup,
  UserAccountSummaryUsername,
} from "@nokia-csf-uxr/ccfk/UserAccountSummary";
import { SignOutButton } from "Components/SignOutButton";
import { setUser, showLoader } from "Store/auth";
import { IStore } from "Store/contentState/ts";
import { RootState } from "Store/mainStore";
import { setTheme } from "Store/theme";
import { getTokenExpiryTime } from "Utils";

// import React, { memo, useEffect, useMemo, useState } from "react";
import { useMsal } from "@azure/msal-react";
import DialogModal from "Components/Elements/DialogModal";
import TesterPanel from "Components/Features/TesterPanel";
import OOT from "Constants/services/oot";
import { callMsGraph } from "graph";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import DialogModal from "./Elements/DialogModal";
// import TesterPanel from "./Features/TesterPanel";

const Header = () => {
  const dispatch = useDispatch();
  const loginSelector = useSelector((state: IStore) => state.authSlice);
  const themeSelector = useSelector((state: RootState) => state.theme);
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const { instance, accounts } = useMsal();
  const firstName = loginSelector.login.givenName;
  const lastName = loginSelector.login.surname;
  // const isTester = useMemo(() => authSelector.tester, [authSelector.role]);
  const { warn, error, info } = authSelector.system.stack;
  const magicLoginLink = `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=id_token%20token&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=openid+profile+email&response_mode=fragment&state=12345&nonce=${authSelector.login.mail}678910&prompt=none&login_hint=${authSelector.login.mail}`;

  const forceTokenRenewal = async () => {
    try {
      const silentRequest = {
        account: accounts[0],
        scopes: ["User.Read"],
        forceRefresh: true, // Force a new token refresh
      };

      // Acquire a new token silently with refreshed cache
      const response = await instance.acquireTokenSilent(silentRequest);

      return response;
    } catch (error) {
      console.error("Token | Error forcing token renewal", error);
      document.querySelector('#silent-login').innerHTML = "Error Extending Token, Reloading in 3 secs."
      setTimeout(() => window.location.reload(), 3000)
      // Handle error
    }
  };

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && toast) {
      if (authSelector.system.stack.error.length > 0) {
        authSelector.system.stack.error.forEach((e) =>
          toast.error(
            e.errorCode && e.errorMsg ? e.errorCode + ": " + e.errorMsg : e.errorMsg ? e.errorMsg : e
          )
        );
      }
      if (authSelector.system.stack.warn.length > 0) {
        authSelector.system.stack.warn.forEach((e) =>
          toast.warn(
            e.errorCode && e.errorMsg ? e.errorCode + ": " + e.errorMsg : e.errorMsg ? e.errorMsg : e,
            {
              pauseOnHover: true,
            }
          )
        );
      }
      if (authSelector.system.stack.info.length > 0) {
        authSelector.system.stack.info.forEach((e) =>
          toast.info(
            e.errorCode && e.errorMsg ? e.errorCode + ": " + e.errorMsg : e.errorMsg ? e.errorMsg : e,
            {
              pauseOnHover: true,
            }
          )
        );
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [error, warn, info]);
  const [countDown, setCountDown] = useState(0);
  const [showTimer, setShowTimer] = useState(false);
  const timeScore = (rT: string) => parseInt(rT.toString().match(/\d+/g).join(''));;
  useEffect(() => {
    if (authSelector.login.adToken && authSelector.login.adToken.hasOwnProperty('idToken')) {
      //Check if an Interval is running and viceversa
      var intervalId = setInterval(async () => {
        let rT: any = getTokenExpiryTime(authSelector.login.adToken.idToken);
        const timeString = rT !== -1 ? rT.split(" ")[0].trim() : null;

        if (rT === -1) {
          setCountDown((orT) => 0);
        }
        if (rT !== -1 && timeString && ((timeString.includes('m') && parseInt(timeString) <= 5) || (!timeString.includes('m') && timeString.includes('s')))) {
          setCountDown((orT) => rT);
        }
        if ( (timeScore(rT) === timeScore(process.env.REACT_APP_showExtendToken ?? "30m")) || (rT== -1)) {
          if (!themeSelector.data.popUp.isOpen.header_tokenExpire) {
            setShowTimer(() => true)
            dispatch(setTheme({
              element: "popUp",
              comp: "header_tokenExpire",
              prop: "isOpen",
              value: true,
            }))

          }

        }
      }, 1000);

    }
    return () => {
      clearInterval(intervalId);
    };
  }, [authSelector?.login?.adToken?.idToken])

  return (
    <>
      <DialogModal isOpen={themeSelector.data.popUp.isOpen.header_tokenExpire} popInfo={{ title: 'Your session is about to expire', msg: "Please refresh the token to extend time" }} type="popup" actionBtns={
        [{
          label: "Extend Time", variant: "call-to-action",
          onAction: async () => {
            document.querySelector('#silent-login').innerHTML = "Please Wait, Connecting with MSAL.."
            let response = await forceTokenRenewal()
            const adToken = response;
            //@ts-ignore
            const accessToken: any = response.accessToken;
            //@ts-ignore
            let callMS = await callMsGraph(response.accessToken)
              .then((response) => {
                setShowTimer(() => false)
                setCountDown(() => 0)
                dispatch(showLoader(false));
                response["accessToken"] = accessToken;
                response["adToken"] = JSON.stringify(adToken);
                dispatch(setUser(response));
                OOT.init({
                  accessToken: accessToken,
                  email: response.mail,
                  idtoken: response.adToken.idToken,
                  redX: {
                    dispatch: dispatch,
                  }
                });
                document.querySelector('#silent-login').innerHTML = "Your Time has been extended"
                dispatch(setTheme({ element: "popUp", comp: "header_tokenExpire", prop: "isOpen", value: false, }))

              })
          }
        }, {
          label: "Logout",
          variant: "neutral",
          onAction: async () => {
            dispatch(setTheme({
              element: "popUp",
              comp: "header_tokenExpire",
              prop: "isOpen",
              value: false,
            }))

            await instance.logoutRedirect({}).then(() => {
              // Handle successful logout
            }).catch((error) => {
              // Handle logout error
            });
          }
        }]

      } >
        <Typography>
          <div id="silent-login"></div>
        </Typography>

      </DialogModal>

      <div className="">
        <ToastContainer />
        <AppHeader style={{ boxShadow: "none" }}>
          <AppBanner variant="compact">
            <AppBannerLogo />
            <AppBannerName>Digital Automation for Campus</AppBannerName>
            <AppBannerNameSecondary>
              Offer Tool
            </AppBannerNameSecondary>
            <AppBannerContent>
              <AppBannerDrawer>
                <AppMenu>
                  <UserAccountSummary>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                      <Avatar>{`${firstName ? firstName.charAt(0) : "."}${lastName ? lastName.charAt(0) : "."
                        }`}</Avatar>
                      <UserAccountSummaryButton

                        onClick={() => {
                          dispatch(
                            setTheme({
                              element: "dropDown",
                              comp: "header_userAccountSummary",
                              prop: "isOpen",
                              value:
                                !themeSelector.data.dropDown.isOpen
                                  .header_userAccountSummary,
                            })
                          );
                        }}
                      >


                        {loginSelector.login.displayName}

                      </UserAccountSummaryButton>
                    </div>
                    <UserAccountSummaryPopup
                      open={
                        themeSelector.data.dropDown.isOpen
                          .header_userAccountSummary
                      }
                    >
                      <UserAccountSummaryHeader>
                      <UserAccountSummaryUsername style={{fontSize:'20px'}}>
                        {loginSelector.login.displayName}
                        </UserAccountSummaryUsername>
                        
                        <UserAccountSummaryDescription onClick={() => {
                         // dispatch(setTheme({ element: "popUp", comp: "header_tokenExpire", prop: "isOpen", value: !false, }))
                        }}>
                          {loginSelector.login.jobTitle}
                        </UserAccountSummaryDescription>
                        <TesterPanel />
                      </UserAccountSummaryHeader>
                      <UserAccountSummaryFooter>
                        <ButtonsRow>
                          <SignOutButton />
                        </ButtonsRow>
                      </UserAccountSummaryFooter>
                    </UserAccountSummaryPopup>
                  </UserAccountSummary>
                </AppMenu>
                {
                  (showTimer) ? (<AppMenu title="Click on the timer to re-open Extend Timer Window"
                    onClick={() => dispatch(setTheme({
                      element: "popUp",
                      comp: "header_tokenExpire",
                      prop: "isOpen",
                      value: true,
                    }))}>
                    {countDown}
                  </AppMenu>) : (<Skeleton style={{ width: '30' }} />)
                }
              </AppBannerDrawer>

            </AppBannerContent>
          </AppBanner>
        </AppHeader>
      </div>
    </>
  );
};

export default memo(Header);
