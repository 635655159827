import { HorizontalDivider, RadioButton } from "@nokia-csf-uxr/ccfk";
import { RadioButtonLabelContent } from "@nokia-csf-uxr/ccfk/RadioButton";
import { TextInputLabelContent } from "@nokia-csf-uxr/ccfk/TextInput";
import { commericalModel } from "Components/Content/configurationArea/Business/system";
import SliderComponent from "Components/Elements/Slider";
import { setProp } from "Store/Actions";
import { RootState, store } from "Store/mainStore";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSolutionLoad } from "./hooks/useSolutionLoad";
import { setDataConfig } from "Store/contentState/configuration_stepperSlice";
import _ from 'lodash';

const FullSolutionsView = () => {
  /** Store selectors */
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const configStepper  = useSelector((state: RootState) => state.config_stepper);
  const fullSolution = useSelector((state: RootState) => state.business.data.options.PTDAC.caseIndustry);
  /** Hooks */
  const dispatch = useDispatch();
  const { referenceSolutionButtons, updateSolutionData, getSegmentDetails,setNDACCompact } = useSolutionLoad();

  /** Component States */
  const [commercialDuration, setCommercialDuration] = useState(true);
  const [commericial_contractDurInfo, setCommercialContractDuration] = useState(commericalModel);
  const businessSelector = useSelector((state: RootState) => state.business);
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );

  if(commericial_contractDurInfo.byIds[dynamicSelector.data.reference_solution.commercial_model.toLocaleLowerCase()] === undefined) {
    dispatch(
      setProp({
        slice: "dynamic",
        key: "reference_solution.commercial_model",
        value: "Opex"
      })
    );
    setCommercialContractDuration(commericalModel);
  } 

  useEffect(()=> {
    loadCommericalContractUIData();
  },[businessSelector.data.options.PTDAC.caseIndustry,configstepperSelector.data.solutionType,businessSelector.data.options.PTDAC.ndac_ref.fullSolType,businessSelector.data.options.PTDAC.ndac_ref.fullMiningType]);

  useEffect(() => {
    if(dynamicSelector.data.reference_solution.commercial_model === 'operating_lease') {
      updateSolutionData('ndac_compact');
    } 
  }, [dynamicSelector.data.reference_solution.commercial_model]);

  const loadCommericalContractUIData = async(refsoln?,commercialModelName?)=> {
    const model = commercialModelName ? commercialModelName : "Opex";
    dispatch(
      setProp({
        slice: "dynamic",
        key: "reference_solution.commercial_model",
        value: model
      })
    );
    const referenceSol = refsoln ? refsoln : dynamicSelector.data.reference_solution.solution;
    if(fullSolution === 'reference_solution' && referenceSol === 'NDAC') {
      const commericial_contractDurInfo = await setNDACCompact();
      commericial_contractDurInfo && setCommercialContractDuration(commericial_contractDurInfo);
    } else {
      setCommercialContractDuration(commericalModel);
     
    }
  }

  /** reference_solution_buttons onchange */
  const buttonSelect = async (option) => {
    dispatch(
      setProp({
        slice: "dynamic",
        key: "reference_solution.solution",
        value: option.value
      })
    );
    // on change of solution type set default to Opex commercial model
    dispatch(
      setProp({
        slice: "dynamic",
        key: "reference_solution.commercial_model",
        value: 'Opex'
      })
    );
    let segmentLoadStatus = true; // by default load the solution data
    let csId = `${option.value.toLowerCase()}_${fullSolution}`;
    if (fullSolution === 'manufacturing') {
      csId = configStepper.data.solution_selected;
      // if selected option is mpw then set only business in stepper data and dont call solutionlaod
      if (option.value === 'MPW') {
        segmentLoadStatus = false;
        const cs = getSegmentDetails(csId);
        const intialStep = [
          { name: 'Business', stepNumber: 1, status: 'current', isComplete: false, selected: true, isValid: true, id: 'business', stepname: 'business' },
        ];
        const dataConfig = [
          { key: "solution_selected", value: cs.segment },
          { key: "isStepperLoading", value: false },
          { key: "solutionCategory", value: 'MPW' },
          { key: "solutionType", value: cs.solutionType },
          { key: "stepper_data", value: intialStep }
        ];
        dispatch(setDataConfig(dataConfig));
      }
    }
    // if selected segment is reference solution,NDAC solution
    loadCommericalContractUIData(option.value);

    if (segmentLoadStatus) {
      // Load the solution steps
      updateSolutionData(csId);
    }
  };

  const onChangeCommercialModel = (option) => {
    let commercialModelValue = commericial_contractDurInfo.byIds[option]?.id === 'opex' ? 'Opex' : 'Capex';
    if(commericial_contractDurInfo.byIds[option]?.id === 'operating_lease') {
      commercialModelValue = commericial_contractDurInfo.byIds[option].id;
    }
    dispatch(
      setProp({
        slice: "dynamic",
        key: "reference_solution.commercial_model",
        value: commercialModelValue
      })
    );
   
    dispatch(setProp({ slice: "business", key: "view.PTDAC.commercial_model", value: option }));
    dispatch(setProp({ slice: "dynamic", key: "reference_solution.contract_duration", value: commericial_contractDurInfo.byIds[option]?.data?.min }));
    dispatch(setProp({ slice: "business", key: "view.PTDAC.contract_duration", value: commericial_contractDurInfo.byIds[option]?.data?.min }));
    loadCommericalContractUIData(dynamicSelector.data.reference_solution.solution, commercialModelValue);
    return;
  }

  const onChangeDuration = (e) => {
    dispatch(
      setProp({
        slice: "dynamic",
        key: "reference_solution.contract_duration",
        value: e.value
      })
    );

    dispatch(setProp({ slice: "business", key: "view.PTDAC.contract_duration", value: e.value }));
  }

  const getDuration = (type) => {
    const commercialModel = convertCommercialModelToLower(dynamicSelector.data.reference_solution.commercial_model);
    const contractDurInfo = commericial_contractDurInfo.byIds[commercialModel] && commericial_contractDurInfo.byIds[commercialModel].data;

    let duration;
    if(contractDurInfo) {
      switch(type) {
        case 'min': duration = contractDurInfo.min.toString();
        break;
  
        case 'max': duration = contractDurInfo.max.toString();
        break;
  
        case 'step': duration = contractDurInfo.stepBy.toString();
      }
    }
    return duration;
  }

  const convertCommercialModelToLower = (commercialDuration) => { 
    return commercialDuration.toLocaleLowerCase();
  }

  return (
    <>
      {
        fullSolution !== "mining" && (<>
          <TextInputLabelContent>Solution Type *</TextInputLabelContent>
          <div
            className="ml mb mt"
            style={{
              display: "flex",
              width: "50%"
            }}
          >
            {referenceSolutionButtons.map((radio, i) => {
              return (
                <Fragment key={i}>
                  <RadioButton
                    key={i}
                    onChange={(e) => {
                      buttonSelect(radio);
                    }}
                    checked={
                      dynamicSelector.data.reference_solution.solution ===
                      radio.value
                    }
                  />
                  <RadioButtonLabelContent>{radio.value}</RadioButtonLabelContent>
                </Fragment>
              );
            })}
          </div></>)
      }
      {commercialDuration && (
        <>
          <HorizontalDivider className="horizontalDivider" />
          <TextInputLabelContent>Commercial Model *</TextInputLabelContent>
          <div
            className="ml mb mt"
            style={{
              display: "flex",
              width: "80%"
            }}
          >
            {commericial_contractDurInfo.allIds.map((option, i) => {
              return (
                <Fragment key={i}>
                  <RadioButton
                    key={i}
                    onChange={(e) => {
                      if (e.target.checked) { onChangeCommercialModel(option) }
                    }}
                    checked={convertCommercialModelToLower(dynamicSelector.data.reference_solution.commercial_model) === option}
                  />
                  <RadioButtonLabelContent>
                    {commericial_contractDurInfo.byIds[option].value}
                  </RadioButtonLabelContent>
                </Fragment>
              );
            })}
          </div>
          <div id="contract-duration">
            <TextInputLabelContent>{`Contract Duration (${commericial_contractDurInfo.byIds[
              convertCommercialModelToLower(dynamicSelector.data.reference_solution.commercial_model)
            ] && commericial_contractDurInfo.byIds[
              convertCommercialModelToLower(dynamicSelector.data.reference_solution.commercial_model)
            ].data.duration
              }) *`}</TextInputLabelContent>
            <div className="ml">
              <SliderComponent
                minDuration={getDuration('min')}
                maxDuration={getDuration('max')}
                stepBy={getDuration('step')}
                sliderStateValue={
                  dynamicSelector.data.reference_solution.contract_duration
                }
                handleChange={(e) => onChangeDuration(e)}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(FullSolutionsView);
