import { SelectItem, Skeleton, Tooltip, Typography } from "@nokia-csf-uxr/ccfk";
import {
  SelectGroupHeading,
  SelectItemClearButton,
  SelectItemInput,
  SelectItemText,
  SelectListItem
} from "@nokia-csf-uxr/ccfk/SelectItem";
import { useValidate } from "Components/Logics/ensoCommonFunctions";
import { handleSalesChannelLogic } from "Components/Logics/systemLogics";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { memo, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProp } from "Store/Actions";
import { RootState, store } from "Store/mainStore";
import { resetTheme, setTheme } from "Store/theme";
import { getTextWidth } from '@nokia-csf-uxr/ccfk/common';

interface selectBarDefaults {
  options: any,
  optionPath?: string, //i.e -> value when select bar is selected
  isOpen?: boolean, // first level of render if its not a key value pair , here it will be id
  renderValue?: string,
  displayKey?: string | null, //1.1 [{id:12332,value:"some text"}] -> to show value add 'value' as display key
  setProperty?: { sliceProperty: string, themeProperty: string },
  isDisabled?: boolean,
  slice?: string,
  variant?: "outlined" | "underlined",
  style?: {},
  onChange?: any,
  handOnSelect?: any,
  onlyHandOnSelect?: boolean,
  truncateListText?:boolean
}
const ENTER_KEY = 'Enter';
const SPACE_KEY = ' ';
const ONE_REM = 0.0625;

const isSelectionKeyPressed = key => key && (key === ENTER_KEY || key === SPACE_KEY);

const SelectBar = ({
  options,
  optionPath, //i.e -> value when select bar is selected
  isOpen, // first level of render if its not a key value pair , here it will be id
  renderValue,
  displayKey = undefined, //1.1 [{id:12332,value:"some text"}] -> to show value add 'value' as display key
  setProperty,
  isDisabled = false,
  slice = "caseDetails",
  variant = "outlined",
  style = {},
  onChange = () => { },
  handOnSelect = () => { },
  onlyHandOnSelect = false,
  truncateListText = true
}: selectBarDefaults) => {
  const [newSearch, setNewSearch] = useState(options);
  const [inputText, setInputText] = useState("");
  const [renderText, setRenderText] = useState(renderValue);
  const [isEdit, setEdit] = useState(false);
  const [listItemWidth, setListItemWidth] = useState(0);
    

  const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
  const selectItemRef: any = useRef(setProperty.sliceProperty);
  const dispatch = useDispatch();
  const isValidated = useValidate();
  const themeSelector = useSelector((state: RootState) => state.theme);

  /* Update List item width */
  useLayoutEffect(() => {
    updateSelectItemWidth();
    window.addEventListener('resize', updateSelectItemWidth);
    return () => {
      window.removeEventListener('resize', updateSelectItemWidth);
    };
  }, [selectItemRef]);

  
  /** updateSelectItemWidth */
  const updateSelectItemWidth = _.throttle(() => {
    // subtract dropdown arrow width from select width subtract
    const selRef = selectItemRef && selectItemRef.current && selectItemRef.current.selectItemRef;
    if (selRef) {
      const { clientWidth: width } = selRef;
      const LIST_ITEM_NON_TEXT_SPACE = 92;
      // const listItemWidthPixels = width - 16 - 24 - 12 - 12 - 12 - 16;
      const listItemWidthPixels = width - LIST_ITEM_NON_TEXT_SPACE;
      setListItemWidth(listItemWidthPixels * ONE_REM);
    }
  }, 50);

   /**
   * Determines whether a tooltip should be displayed based on the width of the text.
   *
   * @param __renderText - The text to be rendered in the list item.
   * @returns A boolean indicating whether the tooltip should be displayed.
   */
  const shouldDisplayTooltip = (__renderText) => {
    const listItemTextWidth = getTextWidth(__renderText); // returns rem
    return (Math.floor(listItemTextWidth) >= Math.floor(listItemWidth));
  }

  const shouldUpdateSelection = (event) => {
    const { key, type } = event;
    // if ESCAPE or TAB or click (outside) event closed the menu clear current input text
    if ((key === 'Escape') || (type === 'mousedown')) {
      setRenderText(null);
      dispatch(
        setTheme({
          element: "select",
          prop: "isOpen",
          comp: setProperty.themeProperty,
          value:
            !themeSelector.data.select.isOpen[setProperty.themeProperty],
        })
      );
    }
  }

  const getFilteredValues = (filterValue) => {
    if (filterValue.length === 0) {
      return options;
    }
    return options.filter((item) => {
      if (item[displayKey]) {
        return item[displayKey].toLowerCase().includes(filterValue.toLowerCase());
      }

    });
  };
  const handleInputChange = (event) => {
    let newText = event.target.value;
    setRenderText(newText);
    setEdit(true)
    return setNewSearch(getFilteredValues(newText));
  };
  const handleInputSubmit = (event) => {

    if (inputText === "") {
      setInputText("");
    }
  };
  const handleSelect = ({ key, payload, property }) => {
    if (onlyHandOnSelect) {
      handOnSelect(payload);
    } else {
      dispatch(setProp({ key: key, value: payload, slice: slice }));
      handOnSelect(payload);
    }
    dispatch(resetTheme());
  };
  const renderClearButton = (props) => {
    if (renderText === "" || renderText == null) {
      return undefined;
    }

    return (
      <SelectItemClearButton
        id={"ClearButton_" + setProperty.sliceProperty}
        aria-label="clear input"
        onClick={() => {
          //setting the state in slice
          dispatch(setProp({ key: setProperty.sliceProperty, value: "", slice: slice }));
          setRenderText("");
          setNewSearch(options);
        }}
      />
    );
  };
  const renderSelectItemBase = () => {
    if (renderValue && renderValue !== "None") {
      setRenderText(renderValue);
    } else if (renderValue == "None" && !isEdit) {
      setRenderText("");
    }


    return (
      <SelectItemInput
        crossOrigin="anonymous"
        onChange={handleInputChange}
        onSubmit={handleInputSubmit}
        renderClearButton={renderClearButton}
        value={renderText} //renderValue
      />
    );
  };
  
  return (
    <>
      {isDisabled ? (
        <Typography typography="BODY">{renderValue}</Typography>
      ) : (Array.isArray(options) && options.length === 0) ? (<Skeleton style={{ width: '250px' }} />) : (
        (

          <>
            {/* <Typography typography="LABEL">CCFK</Typography> */}
            <SelectItem
              onClose={(event) => shouldUpdateSelection(event)}
              maxWidth={true}
              truncateListText={truncateListText}
              max-width={true}
              className="SelectBar"
              key={setProperty.sliceProperty}
              onChange={onChange()}
              searchable={true}
              disabled={isDisabled}
              style={style}
              error={
                isValidated.length > 0
                  ? isValidated.includes(setProperty.sliceProperty)
                  : false
              }
              errorMessage={
                setProperty.sliceProperty === "account" && caseDetailsSelector.data.sales_channel === ""
                  ? "Please select sales channel first"
                  : setProperty.sliceProperty.replace(/_/g, " ").toLowerCase() +
                  " cannot be empty"
              }
              id={setProperty.sliceProperty}
              aria-labelledby="selectitem-component-label"
              variant={variant}
              ref={selectItemRef}
              isOpen={isOpen}
              renderSelectItemBase={renderSelectItemBase}
              onClick={() => {
                setRenderText(null);
                dispatch(
                  setTheme({
                    element: "select",
                    prop: "isOpen",
                    comp: setProperty.themeProperty,
                    value:
                      !themeSelector.data.select.isOpen[setProperty.themeProperty],
                  })
                );
                setNewSearch(options);
              }}
            >
              {newSearch.map((selectOpt, index, arr) => {
                const { key,value,type,icon } = selectOpt;
                const __renderValue = _.get(selectOpt, optionPath);
                const __renderText = _.get(selectOpt, displayKey);
                
                if(type === 'group'){
                  let groupHeading = <div className="__dot_selectGroupHeading">{value}</div>
                  if(truncateListText && shouldDisplayTooltip(value)){
                    groupHeading = <Tooltip tooltip={value} placement="bottom">
                      {groupHeading}
                    </Tooltip>
                  }
                  return <SelectGroupHeading key={key}>
                    {groupHeading}      
                    </SelectGroupHeading>
                }
                else{
                  return (
                    <SelectListItem
                      key={index}
                      selected={renderValue === __renderValue}
                      role="option"
                      onKeyDownCapture={(e) => {
                        if (isSelectionKeyPressed(e.key)) {
                          setRenderText(__renderText);
                          handleSelect({
                            key: setProperty.sliceProperty, // setting the state in slice
                            payload: __renderValue, //setting the state in slice
                            property: setProperty.themeProperty, //setting the state in theme
                          }); //closes that selectbar only
  
                          handleSalesChannelLogic(store.getState());
                        }
                      }}
                      onClick={(e) => {
                        setEdit(false)
                        setRenderText(__renderText);
                        handleSelect({
                          key: setProperty.sliceProperty, // setting the state in slice
                          payload: __renderValue, //setting the state in slice
                          property: setProperty.themeProperty, //setting the state in theme
                        }); //closes that selectbar only
  
                        handleSalesChannelLogic(store.getState());
                      }}
                      tooltipProps={{
                        disable: !(truncateListText && shouldDisplayTooltip(__renderText)),
                        tooltip: __renderText,
                      }}
                    >
                      {icon &&
                        <span className="dot-icon"> {icon} </span>
                      }
                      <SelectItemText key={index}>
                        {displayKey
                          ? __renderText
                          : __renderValue}
                      </SelectItemText>
                    </SelectListItem>
                  );
                }                
              })}
            </SelectItem>
          </>
        )
      )}
    </>    
  );
};
export default memo(SelectBar);
SelectBar.propTypes = {
  options: PropTypes.array,
  optionPath: PropTypes.string,
  displayKey: PropTypes.string,
  renderValue: PropTypes.string,
  isOpen: PropTypes.bool,
  onOpenSelect: PropTypes.any,
  setProperty: PropTypes.object,
  isDisabled: PropTypes.bool,
  slice: PropTypes.string,
  variant: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
  handOnSelect: PropTypes.func,
  onlyHandOnSelect: PropTypes.bool
};
